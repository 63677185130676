import { createBrowserHistory } from "history";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import julImg from "./jul.png";

import { ReactComponent as RoedeLogo } from "assets/img/roede-logo-svart.svg";
import { ReactComponent as RoedeLogoVert } from "assets/img/roede-logo-vert.svg";
import "./styles.scss";

export default function PublicHeader() {
  const [open, setOpen] = useState(false);
  let history = createBrowserHistory();

  useEffect(() => {
    return history.listen((location) => {
      setOpen(false);
    });
  }, [history]);

  return (
    <>
      <header className="header">
        <div className="header__top">
          <div className="logo">
            <Link id="logo_wrapper" to="/">
              <RoedeLogoVert className="mobile" />
              <RoedeLogo className="desktop" />

              {/* <img src={julImg} alt="" /> */}
            </Link>
          </div>
          <div className="buttons">
            <Link className="signupLink" to="/veiledere/">
              Veiledere
            </Link>
            <Link className="instructorLink" to="/blimed/">
              Bli medlem
            </Link>
            <Link className="loginButton" to="/u/">
              Login
            </Link>
            <button
              type="button"
              className="usermenu-toggle"
              aria-controls="sitemenu"
              onClick={() => setOpen(!open)}
            >
              Bruker
            </button>
          </div>
        </div>
        <nav id="usermenu" className="usermenu" data-open={open}>
          <Link className="loginButton" to="/u/">
            Login
          </Link>
          <ul>
            <li>
              <Link to="/veiledere">Finn veileder</Link>
            </li>
            <li>
              <Link to="/sted">Roede der du bor</Link>
            </li>
            <li>
              <Link to="/blimed/">Bli medlem</Link>
            </li>
            <li>
              <a href="https://magasinet.roede.com/tag/solskinnshistorier/">
                Solskinnshistorier
              </a>
            </li>
            <li>
              <Link to="/oppskrifter/">Oppskrifter</Link>
            </li>
            <li>
              <a href="https://butikk.roede.com">Nettbutikk</a>
            </li>
            <li>
              <a href="https://magasinet.roede.com/">Magasin</a>
            </li>
            <li>
              <a href="https://www.roede.com/hjelpesenter">Hjelpesenter</a>
            </li>
            <li>
              <Link to="/podcast">Podcast</Link>
            </li>

            <li>
              <a href="/sok">
                <i className="icon-magnifier-1 foodSearch__icon" /> Søk
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}
