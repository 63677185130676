import React, { Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import Loading from 'components/ui/Loading'
import PublicHeader from 'components/layout/PublicHeader'

const PostalCodePageNew = lazy(() => import('./pages/PostalCodePageNew'))
const BuyMembershipPage = lazy(() => import('./pages/BuyMembershipPage'))
const CourseSearchPage = lazy(() => import('./pages/CourseSearchPage'))

export const CourseWrapper = ({children}) => {
  return <React.Fragment>
    <PublicHeader />

    <Suspense fallback={<Loading />}>
      {children}
    </Suspense>
    </React.Fragment>

}

export function CourseItem() {
  return (
    <CourseWrapper>
      <CourseSearchPage/>
    </CourseWrapper>
  )
}

export function BuyMembershipItem() {
  return (
    <CourseWrapper>
      <BuyMembershipPage />
    </CourseWrapper>
  )
}

export function NettCourseRedirect() {
  return <Navigate to="/sted/digitalkurs/" replace/>
}

export function PostalCodePage() {
  return (
    <CourseWrapper>
      <PostalCodePageNew/>
    </CourseWrapper>
  )
}