import PublicHeader from "components/layout/PublicHeader";
import { lazy } from "react";
import { Link } from "react-router-dom";
import Meta from "./Meta";

import s from "./FrontPage.module.scss";

const LazyLoadedSections = lazy(() => import("./LazyLoadedSections"));

export default function FrontPage() {
  return (
    <>
      <Meta />
      <PublicHeader />
      {/* <div className={s.notice}>
        <div>
          <h2>Tekniske Problemer</h2>
          <p>
            Vi opplever for øyeblikket nedetid i alle våre digitale tjenester,
            inkludert NEDiVEKT appen og kurskjøpsfunksjonalitet, grunnet
            tekniske utfordringer hos vår hostingleverandør. Vi jobber tett
            sammen med leverandøren for å løse problemet så raskt og effektivt
            som mulig. Vi forstår hvilken ulempe dette medfører og ønsker å
            uttrykke vår dypeste beklagelse for eventuelle problemer dette måtte
            forårsake. Vi takker for din tålmodighet.
          </p>
        </div>
      </div> */}
      <div className={s.herogrid}>
        <div>
          <div>
            <h3> Gjør det selv</h3>
            <h2>APP</h2>

            {/* <NEDiVEKTLogo className={s.gridlogo} /> */}
            <ul>
              <li>Lær om vektkontroll</li>
              <li>Ukentlige webinarer</li>
              <li>Måltidsplan og 1600 næringsberegnede oppskrifter</li>
              <li>Daglig kostholdsscore og kontroll på kcal inntak</li>
              <li>Strekkode-scanner for matvarer</li>
              <li>Våre fagfolk gir deg svar</li>
            </ul>

            <Link to="blimed/nedivekt">Få tilgang her</Link>
          </div>
        </div>
        <div>
          <div>
            <h3>Personlig oppfølging</h3>
            <h2>MEDLEMSKAP</h2>
            <span>Bestselger!</span>
            <ul>
              <li>Personlig oppfølging i hele din vektprosess</li>
              <li>
                Møt veileder digitalt eller der du bor (hvis tilgjengelig)
              </li>
              <li>Dedikert veileder følger deg hele veien</li>
              <li>Kickstart om du vil raskt ned i vekt</li>
              <li>Full tilgang til NEDiVEKT app</li>
            </ul>
            <Link to="blimed/medlemskap"> Bli medlem</Link>
          </div>
        </div>
        <div>
          <div>
            <h3>Personlig oppfølging</h3>
            <h2>KURSPAKKE</h2>

            <ul>
              <li>Personlig oppfølging i kursperioden</li>
              <li>
                Møt veileder digitalt eller der du bor (hvis tilgjengelig)
              </li>
              <li>Veilederen følger deg hele kursperioden</li>
              <li>Kickstart om du vil raskt ned i vekt</li>
              <li>Full tilgang til NEDiVEKT app</li>
              <li>4, 8, 10 og 16 ukers pakker</li>
            </ul>
            <Link to="blimed/kurspakke">Velg kurspakke</Link>
          </div>
        </div>
      </div>
      <div className={s.sections}>
        <LazyLoadedSections />
      </div>
    </>
  );
}
