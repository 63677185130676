import React from 'react'
import { Helmet } from 'react-helmet'

export default function Meta() {
  return (
    <Helmet>
      <title>ROEDE – NYE VANER SOM VARER</title>
      <meta name="description" content="En sunn livsstil handler om å bygge gode vaner. Roede-metoden gir deg store og små verktøy for å få det til. Velg mellom kurs i gruppe, en-til-en, nettkurs eller last ned appen vår." />
      <meta property="fb:app_id" content="1471941896435346" />
      <meta property="fb:admins" content="" />
      <meta property="og:locale" content="nb_NO" />
      <meta property="og:locale:alternate" content="nb_NO" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.roede.com/" />
      <meta property="og:title" content="ROEDE – NYE VANER SOM VARER" />
      <meta property="og:description" content="En sunn livsstil handler om å bygge gode vaner. Roede-metoden gir deg store og små verktøy for å få det til. Velg mellom kurs i gruppe, en-til-en, nettkurs eller last ned appen vår." />
      <meta property="og:image" content="https://www.roede.com/assets/img/fb-share.webp" />
      <link rel="canonical" href="https://www.roede.com/" />
    </Helmet>
  )
}
