import AuthContext from "components/auth/AuthContext";
import useAuth from "components/auth/useAuth";
import PageBody from "components/layout/PageBody";
import Loading from "components/ui/Loading";
import LoadingContext from "components/ui/Loading/LoadingContext";
import useLoading from "components/ui/Loading/useLoading";
import ApiProvider from "components/util/ApiProvider";
import ScrollToTop from "components/util/ScrollToTop";
import FrontPage from "pages/FrontPage";
import { Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { BuyMembershipItem, CourseItem, PostalCodePage } from "./Signup";

const U = lazy(() => import("./U"));
const Footer = lazy(() => import("components/layout/PageFooter"));
const HowItWorks = lazy(() => import("./pages/HowItWorks"));
const HowNettcourseWorks = lazy(() => import("./pages/HowNettcourseWorks"));
const HelpPage = lazy(() => import("./pages/HelpPage"));
const Search = lazy(() => import("./pages/SearchPage"));
const Article = lazy(() => import("./pages/ArticlePage"));
const ThanksPage = lazy(() => import("./pages/ThanksPage"));
const DownloadAppPage = lazy(() =>
  import("./pages/ThanksPage/DownloadAppPage")
);
const MembershipSetupPage = lazy(() =>
  import("./pages/ThanksPage/MembershipSetupPage")
);

// This Pages are not tested 100%. It was part of the quick and dirty fix.
const KeepUser = lazy(() => import("./pages/KeepUser"));
const SignupPage = lazy(() => import("./pages/SignupPage"));
const ResetPassword = lazy(() => import("./pages/ResetPasswordPage"));
const CoursePage = lazy(() => import("./pages/CoursePage"));
const NettCoursePage = lazy(() => import("./pages/NettCoursePage"));
const InstructorPage = lazy(() => import("./pages/InstructorPage"));
const InstructorsPage = lazy(() => import("./pages/InstructorsPage"));
const Kitchen = lazy(() => import("./Kitchen"));
const BmiPage = lazy(() => import("./pages/BmiPage"));
const Partners = lazy(() => import("./pages/Partners"));
const Podcast = lazy(() => import("./pages/Podcast"));

const Privacy = lazy(() => import("./pages/Privacy"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));

const ConsentPage = lazy(() => import("./pages/ConsentPage"));

function WrappedApp() {
  const auth = useAuth();
  const loading = useLoading();
  return (
    <AuthContext.Provider value={auth}>
      <LoadingContext.Provider value={loading}>
        <ScrollToTop />
        <Suspense fallback={<Loading />}>
          <PageBody>
            <Routes>
              <Route path="/">
                <Route index element={<FrontPage />} />
                <Route path="u/*" element={<U />} />
                <Route path="/hvordan-fungerer-det" element={<HowItWorks />} />
                <Route
                  path="/hvordan-fungerer-nettkurset"
                  element={<HowNettcourseWorks />}
                />
                <Route path="/behold-bruker" element={<KeepUser />} />
                <Route path="/hjelpesenter" element={<HelpPage />} />
                <Route path="samtykke">
                  <Route index element={<ConsentPage />} />
                </Route>
                <Route path="blimed">
                  <Route path=":slug" element={<BuyMembershipItem />} />
                  <Route index element={<SignupPage />} />
                </Route>
                <Route path="velkommen">
                  <Route index element={<ThanksPage />} />
                  <Route path="download" element={<DownloadAppPage />} />
                  <Route
                    path=":membershipId"
                    element={<MembershipSetupPage />}
                  />
                </Route>

                <Route
                  path="/tilbakestille-passord"
                  element={<ResetPassword />}
                />
                <Route path="/sok" element={<Search />} />
                <Route path="/artikkel/:slug" element={<Article />} />

                <Route path="nettkurs">
                  <Route path=":courseId">
                    <Route path=":day">
                      <Route path=":frequency" element={<NettCoursePage />} />
                      <Route index element={<NettCoursePage />} />
                    </Route>
                    <Route index element={<NettCoursePage />} />
                  </Route>
                </Route>

                <Route path="veiledere">
                  <Route path=":slug">
                    <Route path=":filter" element={<InstructorPage />} />
                    <Route index element={<InstructorPage />} />
                  </Route>
                  <Route index element={<InstructorsPage />} />
                </Route>

                <Route path="oppskrifter/*" element={<Kitchen />} />

                <Route path="gull">
                  <Route
                    path=":coupon"
                    element={<Navigate to="/blimed/nedivekt/" replace />}
                  />
                  <Route
                    index
                    element={<Navigate to="/blimed/nedivekt/" replace />}
                  />
                </Route>
                <Route path="kurs">
                  <Route index element={<CourseItem />} />
                  <Route path=":courseId" element={<CoursePage />} />
                </Route>
                <Route path="sted">
                  <Route index element={<CourseItem />} />
                  <Route path=":placeId" element={<PostalCodePage />} />
                </Route>

                <Route path="bmi" element={<BmiPage />} />
                <Route path="partnere" element={<Partners />} />
                <Route path="podcast" element={<Podcast />} />
                <Route path="privacy" element={<Privacy />} />
                <Route
                  path="terms-and-conditions"
                  element={<TermsAndConditions />}
                />
              </Route>
            </Routes>
          </PageBody>
          <Footer />
        </Suspense>
      </LoadingContext.Provider>
    </AuthContext.Provider>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <ApiProvider>
        <WrappedApp />
      </ApiProvider>
    </BrowserRouter>
  );
}
